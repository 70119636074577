import cx from 'classnames'
import { Link } from "gatsby"
import React from "react"
import { rhythm, scale } from "../utils/typography"


const Layout = ({ location, title, children, className }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  // if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
        }}
        className={cx('text-center mb-10')}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  // } else {
  //   header = (
  //     <h3
  //       style={{
  //         marginTop: 0,
  //       }}
  //     >
  //       <Link
  //         style={{
  //           boxShadow: `none`,
  //           color: `inherit`,
  //         }}
  //         to={`/`}
  //       >
  //         {title}
  //       </Link>
  //     </h3>
  //   )
  // }
  return (
    <div
      style={{
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
      className={cx( className)}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer className='mt-10'>
        © {new Date().getFullYear()}
      </footer>
    </div>
  )
}

export default Layout
